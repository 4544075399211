import * as React from "react";
import Layout from "../components/layout";
import { partnersData } from "../data/partners-data";
import Seo from "../components/seo";
import PartnerSection from "../components/Pages/Partners/PartnerSection";
import Banner from "../components/Banner";
const PartnersPage = () => {
  return (
    <Layout>
      <Seo title="Partners" url="https://migrantresilience.org/partners" />
      <Banner
        src="/images/Banner Partners Page - Who we are.jpg"
        position="object-center"
      />
      {/* <BannerSide
        heading="Migrants Resilience Collaborative (MRC)"
        image="/images/part-banner.jpg"
        text="is India's largest non-governmental, grassroots-led collaborative dedicated to migrant workers and their families. We have forged partnerships with state governments, institutions, textile companies and real estate investors and developers, to improve the access of social security schemes for workers."
      /> */}
      <div className="w-full flex flex-col justify-center items-center relative lg:py-20 py-12">
        <p className="text-darkblue font-semibold md:text-base text-sm text-center lg:px-28 md:px-12 px-4">
          The Migrants Resilience Collaborative is committed to fostering
          meaningful partnerships with different stakeholders centred around the
          communities we serve. We work with community-based organizations,
          governments, industry and philanthropy to drive our strategic goals.
        </p>
        {partnersData.map((row, key) => (
          <PartnerSection data={row} key={"partnerSection" + key} />
        ))}
      </div>
    </Layout>
  );
};
export default PartnersPage;
