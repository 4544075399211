import React from "react";

export default function Partners() {
  const blueDots =
    "absolute md:top-32 top-16 right-40 w-80 object-contain opacity-80 -z-0";
  return (
    <div className="w-full flex flex-col justify-center items-center relative">
      <div className="w-full lg:pb-20 py-12 bg-white lg:px-28 md:px-12 px-4 ">
        <div className="w-full flex flex-col justify-center items-center relative z-0">
          <h2 className="text-left mb-0 text-darkblue md:text-4xl text-2xl transition-all duration-300 ease font-bold uppercase">
            Our Industry Partners
          </h2>
        </div>
        <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 lg:grid-rows-1 md:grid-rows-2 grid-rows-4 w-full m-auto md:gap-4 gap-6 relative lg:mt-8 mt-4">
          <LogoBox
            link="https://www.tatarealty.in/"
            title="Tata Realty"
            icon="/images/tata.svg"
          />
          <LogoBox
            link="https://www.godrejproperties.com/"
            title="Godrej Properties"
            icon="/images/godrej.svg"
          />
          <LogoBox
            link="https://www.total-environment.com/homes"
            title="Total environment"
            icon="/images/te-logo.svg"
          />
          <LogoBox
            link="https://www.mahindralifespaces.com/"
            title="Mahindra"
            icon="/images/mahindra.svg"
          />
          <img
            src="/images/blue-dots.png"
            alt="blue dots"
            className={blueDots}
          />
        </div>
      </div>
      <div className="w-full lg:py-20 py-12 bg-white lg:px-28 md:px-12 px-4 bg-sectionBlue">
        <div className="w-full flex flex-col justify-center items-center relative z-0">
          <h2 className="text-left mb-0 text-darkblue md:text-4xl text-2xl transition-all duration-300 ease font-bold uppercase">
            Our Strategic Partners
          </h2>
        </div>
        <div className="grid grid-cols-2 lg:w-6/12 md:w-8/12 w-full m-auto md:gap-12 gap-4 relative lg:mt-8 mt-4">
          <LogoBox
            link="https://www.edelgive.org/"
            title="edelgive"
            icon="/images/EdelGive-www.edelgive.jpg"
          />
          <LogoBox
            link="https://globaldevincubator.org/"
            title="GDI"
            icon="/images/GDI.png"
          />
          <img
            src="/images/blue-dots.png"
            alt="blue dots"
            className={blueDots}
          />
        </div>
      </div>
    </div>
  );
}
export const LogoBox = ({ icon, title, link }) => {
  return link ? (
    <a
      href={link}
      target="_blank"
      className="z-1 bg-white shadow-lg hover:shadow-2xl transition-all duration-300 ease border-darkblue border-1 rounded-xl px-8 w-full md:h-40 h-40 flex items-center justify-center"
    >
      <img
        src={icon}
        alt={title}
        className="rounded-xl object-contain object-center w-full max-h-32"
      />
    </a>
  ) : (
    <div className="z-1 bg-white shadow-lg hover:shadow-2xl transition-all duration-300 ease border-darkblue border-1 rounded-xl px-8 w-full md:h-40 h-40 flex items-center justify-center">
      <img
        src={icon}
        alt={title}
        className="rounded-xl object-contain object-center w-full max-h-32"
      />
    </div>
  );
};
