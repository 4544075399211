import React from "react";
import { LogoBox } from "../Partnerships/Partners";
export default function PartnerSection({ data }) {
  const { description, title, partners, className } = data;
  console.log(className);
  const blueDots =
    "absolute md:top-16 top-16 right-40 w-80 object-contain opacity-80 -z-0";
  return (
    <div className="w-full md:py-16 py-12 bg-white odd:bg-sectionBlue lg:px-28 md:px-12 px-4 overflow-hidden">
      <div className="w-full flex flex-col justify-center items-center relative z-0 gap-4">
        <h2 className="text-center mb-0 text-darkblue md:text-4xl text-2xl transition-all duration-300 ease font-bold uppercase">
          {title}
        </h2>
        <div
          className="text-body md:text-base text-sm text-center"
          dangerouslySetInnerHTML={{ __html: description }}
        />
      </div>
      <div className={className}>
        {partners.map((row, key) => (
          <LogoBox
            key={"logoBox" + key}
            link={row.link}
            title={row.title}
            icon={row.icon}
          />
        ))}
        <img src="/images/blue-dots.png" alt="blue dots" className={blueDots} />
      </div>
      {data?.tableData && (
        <div className="w-full h-auto border-black/20 border-[1px] bg-white mt-16">
          <table className="table-fixed w-full">
            <thead className="w-full">
              <tr className="border-b-[1px] border-black/20 border-opacity-50 w-full bg-darkblue">
                <th className="w-2/12 text-white text-left font-bold py-2 font-raleway text-xs md:text-base px-4">
                  S.No.
                </th>
                <th className="lg:w-5/12 w-4/12 text-white text-left font-bold py-2 font-raleway text-xs md:text-base px-4">
                  Partner
                </th>
                <th className="lg:w-2/12 w-2.5/12 text-white md:text-left text-center font-bold py-2 font-raleway text-xs md:text-base px-4">
                  Number of districts
                </th>
                <th className="lg:w-3/12 w-3.5/12 text-white md:text-left text-center font-bold py-2 font-raleway text-xs md:text-base px-4">
                  Name of districts
                </th>
              </tr>
            </thead>
            <tbody className="w-full">
              {data?.tableData?.map((row, key) => (
                <tr
                  className="border-b-[1px] border-black/20 w-full"
                  key={"row" + key}
                >
                  <TD>{row.id}</TD>
                  <TD>{row.partner}</TD>
                  <TD>{row.districts}</TD>
                  <TD>{row.districtNames}</TD>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      {data.email && (
        <div className="md:text-base text-sm text-center w-full mt-6 font-medium text-darkblue">
          To know more email us on{" "}
          <a
            href={`mailto=${data.email}`}
            className="text-blue hover:underline"
          >
            {data.email}
          </a>
        </div>
      )}
    </div>
  );
}

const TD = ({ children }) => {
  return (
    <td className="text-sm md:text-base text-body font-body py-2 px-4">
      {children}
    </td>
  );
};
