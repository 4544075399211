export const partnersData = [
  {
    className:
      "grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 w-full m-auto md:gap-12 gap-4 relative lg:mt-8 mt-4",
    title: "Core Partners",
    description:
      "Jan Sahas, Global Development Incubator and EdelGive Foundation are the strategic partners that manage the governance and program delivery of the Migrants Resilience Collaborative.",
    partners: [
      {
        link: "https://jansahas.org/",
        title: "Jan Sahas",
        icon: "/images/jansahas.png",
      },
      {
        link: "https://www.edelgive.org/",
        title: "edelgive",
        icon: "/images/EdelGive-www.edelgive.jpg",
      },
      {
        link: "https://globaldevincubator.org/",
        title: "GDI",
        icon: "/images/GDI.png",
      },
    ],
  },
  {
    className:
      "grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 w-full m-auto md:gap-12 gap-4 relative lg:mt-8 mt-4",
    title: "Community Based Organizations",
    description:
      "A key anchor to our program design, is to engage with grassroot organization who have significant and specialised experience in particular geographies, a deep understanding and relationship with communities. We partner with these organizations with strong linkages to our mission. Through our partnerships, we offer guidance and technical support on program implementation.",
    partners: [
      {
        icon: "/images/cbo/BR_GVYT_Logo.jpg",
      },
      {
        icon: "/images/cbo/JH_MMS_logo.png",
      },
      {
        icon: "/images/cbo/MH_Nirman_Logo.jpg",
      },
      {
        icon: "/images/cbo/MH_RDC_Logo-pdf.jpg",
      },
      {
        icon: "/images/cbo/MP_GSS_Logo.jpg",
      },
      {
        icon: "/images/cbo/MP_IDWYC_Logo.png",
      },
      {
        icon: "/images/cbo/MP_JSSES_Logo.png",
      },
      {
        icon: "/images/cbo/MP_Mahad_Logo.jpg",
      },
      {
        icon: "/images/cbo/MP_Samaan_Logo.png",
      },
      {
        icon: "/images/cbo/MP_YVM_Logo.jpg",
      },
      {
        icon: "/images/cbo/TS_Aashray_Logo.jpg",
      },
      {
        icon: "/images/cbo/TS_Ankita_Logo.jpg",
      },
      {
        icon: "/images/cbo/TS_Progressive_logo.jpg",
      },
      {
        icon: "/images/cbo/UP_AIM_logo.jpg",
      },
      {
        icon: "/images/cbo/UP_GVSS_Logo.png",
      },
      {
        icon: "/images/cbo/UP_Rosa_logo.jpg",
      },
      {
        icon: "/images/cbo/WB_NSKK_Logo.jpg",
      },
    ],
    tableData: [
      {
        id: 1,
        districts: 2,
        partner: "Gram Vikas Yuva Trust",
        districtNames: "Madhubani, Kishanganj",
      },
      {
        id: 2,
        districts: 2,
        partner: "Gramin Swawalamban Samiti",
        districtNames: "Tikamgarh, Sheopur",
      },
      {
        id: 3,
        districts: 1,
        partner: "Institute for Development of Youth, Women and Child",
        districtNames: "Chindwara",
      },
      {
        id: 4,
        districts: 1,
        partner: "MAHAD",
        districtNames: "Harda",
      },
      {
        id: 5,
        districts: 1,
        partner: "Mahila Mukti Sanstha",
        districtNames: "Hazaribag",
      },
      {
        id: 6,
        districts: 2,
        partner: "Nari O Shishu Kalyan Kendra",
        districtNames: "Murshidabad, Birbhum",
      },
      {
        id: 7,
        districts: 2,
        partner: "Nirman - Pune",
        districtNames: "Pune, Raigad",
      },
      {
        id: 8,
        districts: 1,
        partner: "The Progressive Education Foundation",
        districtNames: "Medchal",
      },
      {
        id: 9,
        districts: 1,
        partner: "RDC",
        districtNames: "Beed",
      },
      {
        id: 10,
        districts: 2,
        partner: "Samaan Society",
        districtNames: "Dhar, Jhabua",
      },
      {
        id: 11,
        districts: 2,
        partner: "Yuva Vikas Mandal",
        districtNames: "Sagar, Sehore",
      },
      {
        id: 12,
        districts: 1,
        partner: "Jan Sahas Empowerment Society",
        districtNames: "Indore",
      },
      {
        id: 13,
        districts: 1,
        partner: "ROSA Sansthan",
        districtNames: "Ghazipur",
      },
      {
        id: 14,
        districts: 1,
        partner: "AIM Trust",
        districtNames: "Unnao",
      },
      {
        id: 15,
        districts: 1,
        partner: "Gramin Vikas Sewa Sansthan",
        districtNames: "Ambedkarnagar",
      },
      {
        id: 16,
        districts: 3,
        partner:
          "Association for Needy and Kindle the Illiterate Through Action (ANKITA)",
        districtNames: "Vikarabad, Hyderabad",
      },
      {
        id: 17,
        districts: 1,
        partner: "Aashray - Home away from Home",
        districtNames: "Rangareddy, Mahbubnagar",
      },
    ],
  },
  {
    className:
      "grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 w-full m-auto md:gap-12 gap-4 relative lg:mt-8 mt-4",
    title: "Industry",
    description:
      "We believe industry has a key role to play in ensuring migrant workers are resilient. We engage large industries to impress upon key decision makers, the need to norm social protections in their value chains. We do this by deploying social security delivery models at employer’s work sites and by engaging with investors and alliances in India and globally to drive stronger incentives for downstream actors to facilitate social security delivery.<br/><br/>Till date we have partnered with 16 real estate companies, 2 investors and one large real estate alliance in India.<br/><br/>List of partners: Rohan Builders & Developers Pvt Ltd, Mahindra Lifespaces Developers Ltd, Godrej Properties Ltd, Tata Realty & Infrastructure Ltd, Total Environment Building Systems Pvt Ltd",
    partners: [
      {
        icon: "/images/industry/Canada_Pension_Plan_Investment_Board_TATA_Realty_and_Infrastruct.jpg",
      },
      {
        icon: "/images/industry/GPL_Logo.jpg",
      },
      {
        icon: "/images/industry/rohan builders.jpg",
      },
      {
        icon: "/images/industry/TATA-Realty-and-Infrastructure.jpg",
      },
      {
        icon: "/images/industry/mahindra_logo_vertical.webp",
      },
    ],
    // email: "test@test.com",
  },
  {
    className:
      "grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 w-full m-auto md:gap-12 gap-4 relative lg:mt-8 mt-4",
    title: "Government",
    description:
      "We activity engage with state governments on system level changes that address the gaps between social security policy and implementation. We partner with Labour Departments and Building and Other Construction Workers’ (BOCW) Welfare Boards to assist in the analysis, design and implementation of tech-enabled scheme delivery infrastructure to help migrant workers access social security better, faster and cheaper. We also actively engage with State Departments of Food and Civil Supplies on increasing the uptake of the One Nation, One Ration Card Scheme.",
    partners: [
      {
        icon: "/images/gov/Chattisgarh Labour Department.png",
      },
      {
        icon: "/images/gov/Government of Telangana.png",
      },
      {
        icon: "/images/gov/logo-chhatisgarh.jpg",
      },
      {
        icon: "/images/gov/Madhya Pradesh.png",
      },
    ],
    // email: "test@test.com",
  },
];
